import {Component} from 'react';
import { urls, version } from '../../config';

export class ProfileDropDownHelper extends Component{

    getMyEvents = (token, setMyEvents) => {
    
        let responseCode = 200;    
    
        fetch(urls.eventcore + 'SignUpUI/get-my-events', {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "CC-Client": `Crowdcatcher v${version}`,
            "Authorization": "Bearer " + token
          }
        })
        .then(response=>{
            responseCode = response.status;
            if (responseCode === 204 || responseCode === 401){
              setMyEvents(null);
              return;
            }

            return response.json();
        })            
        .then(data=>{
    
            if (responseCode === 200){
    
                setMyEvents(data);
            }
        },
        (error)=>{
          console.log("FAILED!\n[error code: 864024]");
        });    
    }

}