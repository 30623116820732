import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import StandardLayout from './StandardLayout';
import ErrorLayout from './ErrorLayout';
import loadable from '@loadable/component';
import CookieDisplay from '../legal/CookieDisplay';
import { defaultWebPage } from '../config';
import { Watermark } from '@hirohe/react-watermark';
import { isNil } from 'lodash';
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const DashboardLayout = loadable(() => import('./DashboardLayout'));
const Privacy = loadable(() => import('../components/landing/Privacy'));
const Terms = loadable(() => import('../components/landing/Terms'));

const DefaultHomePage = () => {
  window.location = defaultWebPage;

  return "";
}

const Layout = () => {
  
  useEffect(() => {
    AuthBasicLayout.preload();
    
  }, []);

  const environment = useMemo(()=>{

    if (process.env.NODE_ENV.toLowerCase().includes("development")){
      return ". Development ."
    }
    else if (process.env.REACT_APP_ENV.toLowerCase() === "testing"){
      return ". Testing ."
    }
    else{
      return null;
    }
  },[]);

  return (
    <Watermark textSize={32} text={environment} show={!isNil(environment)}>
      <Router fallback={<span />}>
        <Switch>
          <Route path="/" exact component={DefaultHomePage} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/terms" exact component={Terms} />
          <Route path="/auth" component={AuthBasicLayout} />
          <Route path="/errors" component={ErrorLayout} />
          <Route path="/e" component={StandardLayout} />
          <Route path="/checkin" component={StandardLayout} />
          <Route path="/data-export" component={StandardLayout} />
          <Route path="/quick-login" component={StandardLayout} />
          <Route component={DashboardLayout} />
        </Switch>
        <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
        <CookieDisplay />
      </Router>
    </Watermark>
  );
};

export default Layout;
