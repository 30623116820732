import React, { useContext } from 'react';
import { settings, version } from './config';

export const ApiContext = React.createContext();

export const useApi = () => useContext(ApiContext);

export const Method = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
};

export const ApiProvider = ({ children }) => {

    const basicQuery = (url, token, setResult, errorCode) =>{

        basicMutation(url, null, Method.GET, token, setResult, errorCode, null);
    }

    const basicMutation = (url, body, method, token, setResult, errorCode, resetForm) =>{

        let responseCode = 200;    

        let headers = {
            "Content-Type": "application/json",
            "CC-Client": `Crowdcatcher v${version}`
        };

        if (token){
            headers.Authorization = "Bearer " + token;
        }

        if (settings.isDebug){
            console.log(`${url} ${method}`);
            console.log(token);
            console.log(body);
        }
      
        fetch(url, {
            method: method,
            headers: headers,
            body: body ? JSON.stringify(body) : null,
        })
        .then(response=>{
            responseCode = response.status;
            if (settings.isDebug){
                console.log(`${url} ${responseCode}`);
            }
            if (responseCode === 404){
                window.location = "/errors/404";
                return null;
            }
            else if (responseCode === 401){
                window.location = "/error/401";
                return null;
            } 
            else if (responseCode === 204){
                if (resetForm){
                    setResult(null, false, resetForm);
                }
                else{
                    setResult(null);
                }
                return null;
            } 
            else{
                return response.json();
            }
        })            
        .then(data=> {

            if (!data){
                return;
            }

            if (settings.isDebug){
                console.log(data);
            }
            
            if (responseCode >= 200 && responseCode <= 299){
                if (resetForm){
                    if (setResult){
                        setResult(data, false, resetForm);
                    }
                }
                else{
                    if (setResult){
                        setResult(data);
                    }
                }
            }
            else {
                if (resetForm){
                    if (setResult){
                        setResult(data, true, resetForm);
                    }
                }
                else{
                    if (data){
                        if (setResult){
                            setResult(data);
                        }
                    }
                }
            }
        },
        (error)=>{
            if (settings.isDebug){
                console.log(`${responseCode} ${errorCode}`);
                console.log(url);
                console.log(token);
                if (error){
                    console.log(error);
                }
            }
            console.log(`FAILED!${errorCode ? "\n[error code: " + errorCode + "]" : ""}`);
        });
    }

    return (
        <ApiContext.Provider
            value={{
                basicQuery,
                basicMutation,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
};
